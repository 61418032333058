import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { HomepageQuery } from '../data';
import { Layout, SEO, GraphQLBlock, HomeHero } from '../blocks';
import { SkillTile } from '../components';

const serviceIcon = require('../assets/icons/icon-services-badges.svg');

const IndexPage = () => (
  <StaticQuery
    query={HOMEPAGE_QUERY}
    render={({ markdownRemark: { frontmatter, html } }: HomepageQuery) => {
      return (
        <Layout>
          <SEO
            title={`NuWave Commerce | ${frontmatter.title}`}
            keywords={[`gatsby`, `application`, `react`]}
          />
          <HomeHero {...frontmatter} />
          <section className="container-fluid bg-white">
            <div className="container mx-auto">
              <div className="flex flex-wrap items-center">
                <div className="w-full p-4 md:w-1/2">
                  <div className="mx-auto" style={{ maxWidth: 450 }}>
                    <img src={serviceIcon} style={{ maxWidth: '100%' }} />
                  </div>
                </div>
                <div
                  className="w-full md:w-1/2 px-4"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>
            </div>
          </section>
          <section className="container-fluid bg-white py-12">
            <div className="container mx-auto">
              <div className="flex flex-wrap">
                {frontmatter.skills.map(skill => (
                  <SkillTile key={skill.icon} {...skill} />
                ))}
              </div>
            </div>
          </section>
          <GraphQLBlock />
        </Layout>
      );
    }}
  />
);

const HOMEPAGE_QUERY = graphql`
  query HomepageQuery {
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      id
      html
      frontmatter {
        ...HomeHero
        skills {
          ...SkillTile
        }
      }
    }
  }
`;
export default IndexPage;
